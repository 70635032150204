import { Dialog, Button } from '@eppendorf/vnls-react-components';
import { Organization } from '@eppendorf/vnls-user-tenant-utils';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddOrganization } from '$features/organizations';
import {
  OrganizationForm,
  OrganizationFormId,
  OrganizationFormMappers,
  OrganizationFormProfile,
} from '$features/organizations/form';

export interface Props {
  onOrganizationAdded: (organization: Organization) => void;
}

export function AddOrganizationScreen({ onOrganizationAdded }: Props) {
  const { t } = useTranslation();
  const { mutateAsync, isLoading } = useAddOrganization();
  const subscriptionNames = ['Essentials', 'SampleSafetyPlus'];
  const typeNames = ['Academia', 'Industry'];
  const organizationForm = useForm<OrganizationFormProfile>({
    defaultValues: {
      subscription: subscriptionNames[0],
      type: typeNames[0],
    },
  });

  async function handleAddOrganization(
    formValues: OrganizationFormProfile,
  ): Promise<void> {
    try {
      const { data: organization } = await mutateAsync(
        OrganizationFormMappers.mapFormValuesToCreateRequest(formValues),
      );

      onOrganizationAdded(organization);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 409) {
        organizationForm.setError('adminEmail', {
          type: 'conflict',
          message: t('addOrganization.adminUserAlreadyExists'),
        });
      }
    }
  }

  return (
    <>
      <Dialog.Title>{t('addOrganization.addNewOrganization')}</Dialog.Title>

      {/* eslint-disable-next-line react/jsx-props-no-spreading -- FormProvider does not privde a prop to give the whole form context */}
      <FormProvider {...organizationForm}>
        <OrganizationForm
          types={typeNames}
          subscriptions={subscriptionNames}
          onSubmit={(organization) => handleAddOrganization(organization)}
        />
      </FormProvider>

      <Dialog.Actions>
        <Dialog.Close>
          <Button variant="secondary">{t('shared.cancel')}</Button>
        </Dialog.Close>
        <Button
          type="submit"
          className="btn--solid"
          disabled={isLoading}
          formId={OrganizationFormId}
        >
          {t('addOrganization.create')}
        </Button>
      </Dialog.Actions>
    </>
  );
}
