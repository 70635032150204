import type {
	Organization,
	OrganizationWithDevices,
	PaginatedResponse,
	User,
} from "@eppendorf/vnls-user-tenant-utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { AxiosError, AxiosResponse } from "axios";

import { fetcher } from "$shared/fetch";
import {
	createPathWithQueryParams,
	FUNCTION_URL_REPORTS_BASE_PATH,
	MIGRATION_BASE_PATH,
	objectToURLSearchParams,
	ORGANIZATIONS_YGGDRASIL_BASE_PATH,
} from "$shared/utils/api-paths";

export const organizationQueryKey = "organizations";

export const useOrganizationsWithQueryParams = (queryParams = {}) => {
	const queryKey = [
		organizationQueryKey,
		...objectToURLSearchParams(queryParams).values(),
	];

	return useQuery(
		queryKey,
		async () =>
			fetcher.get<PaginatedResponse<OrganizationWithDevices>>(
				createPathWithQueryParams(
					ORGANIZATIONS_YGGDRASIL_BASE_PATH,
					queryParams,
				),
			),
		{
			keepPreviousData: true,
		},
	);
};

export const useGetOrganization = (id: string) => {
	const queryKey = [organizationQueryKey, id];

	return useQuery(
		queryKey,
		async () =>
			fetcher.get<Organization>(`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}`),
		{
			enabled: !!id,
		},
	);
};

export const useGetMigrationReport = (legacyTenantId: string) => {
	const queryKey = [organizationQueryKey, "migration-report", legacyTenantId];

	return useQuery(
		queryKey,
		async () => {
			try {
				const { content } = await fetcher.get<{ content: string }>(
					`${MIGRATION_BASE_PATH}/migration/reportContent/${legacyTenantId}`,
				);
				return content;
			} catch (error) {
				// eslint-disable-next-line no-console -- for debugging
				console.log(error);
				return null;
			}
		},
		{
			enabled: !!legacyTenantId,
		},
	);
};

export const useAddOrganization = () => {
	const queryClient = useQueryClient();
	const addOrganization = async (organization: Partial<Organization>) =>
		fetcher.post<Partial<Organization>, Organization>(
			ORGANIZATIONS_YGGDRASIL_BASE_PATH,
			organization,
		);

	return useMutation<
		AxiosResponse<Organization>,
		AxiosError,
		Partial<Organization>
	>(addOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useUpdateOrganization = () => {
	const queryClient = useQueryClient();
	const updateOrganization = async (organization: Partial<Organization>) => {
		const { id, ...organizationWithoutId } = organization;
		return fetcher.patch<Partial<Organization>, Organization>(
			`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}`,
			organizationWithoutId,
		);
	};

	return useMutation<Organization, AxiosError, Partial<Organization>>(
		updateOrganization,
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries([organizationQueryKey]);

				queryClient.setQueryData([organizationQueryKey, data.id], data);
			},
		},
	);
};

export const useDeleteOrganization = () => {
	const queryClient = useQueryClient();
	const deleteOrganization = async (id: string) =>
		fetcher.delete(`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}`);
	return useMutation<AxiosResponse, AxiosError, string>(deleteOrganization, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useMigrateOrganizations = () => {
	const migrateOrganizations = (legacyTenantId: string) =>
		fetcher.post<unknown, { tenantId: string }>(
			`${MIGRATION_BASE_PATH}/migration/migrateTenant`,
			{
				legacyTenantId,
			},
		);
	const queryClient = useQueryClient();
	return useMutation<AxiosResponse, AxiosError, string>(migrateOrganizations, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useMigrateEvents = () => {
	const migrateEvents = (legacyTenantId: string) =>
		fetcher.post<unknown, { tenantId: string }>(
			`${MIGRATION_BASE_PATH}/migration/migrateEvents`,
			{
				legacyTenantId,
			},
		);
	const queryClient = useQueryClient();
	return useMutation<AxiosResponse, AxiosError, string>(migrateEvents, {
		onSuccess: () => {
			queryClient.invalidateQueries([organizationQueryKey]);
		},
	});
};

export const useMigrateTelemetry = () => {
  const migrateEvents = (legacyTenantId: string) =>
    fetcher.post<unknown, { tenantId: string }>(
      `${MIGRATION_BASE_PATH}/migration/migrateTelemetry`,
      {
        legacyTenantId,
      },
    );
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, string>(migrateEvents, {
    onSuccess: () => {
      queryClient.invalidateQueries([organizationQueryKey]);
    },
  });
};

export const useResendInvite = () => {
	const queryClient = useQueryClient();
	const updateInviteSendAt = async (id: string) =>
		fetcher.post<unknown, Organization>(
			`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${id}/actions/resend-invite`,
			undefined,
		);

	return useMutation<AxiosResponse<Organization>, AxiosError, string>(
		updateInviteSendAt,
		{
			onSuccess: ({ data }) => {
				queryClient.invalidateQueries([organizationQueryKey]);

				queryClient.setQueryData([organizationQueryKey, data.id], data);
			},
			onError: (error) => {
				// eslint-disable-next-line no-console -- for debugging
				console.log(error);
			},
		},
	);
};

export const useInviteUsers = () => {
	const inviteAllUsers = (legacyTenantId: string) =>
		fetcher.post<unknown, { tenantId: string }>(
			`${MIGRATION_BASE_PATH}/migration/invite-all-users`,
			{
				legacyTenantId,
			},
		);

	const queryClient = useQueryClient();
	return useMutation<AxiosResponse<{ tenantId: string }>, AxiosError, string>(
		inviteAllUsers,
		{
			onSuccess: ({ data }) => {
				queryClient.invalidateQueries([organizationQueryKey]);

				queryClient.setQueryData([organizationQueryKey, data.tenantId], data);
			},
			onError: (error) => {
				// eslint-disable-next-line no-console -- for debugging
				console.log(error);
			},
		},
	);
};

export const downloadMigrationReport = async (legacyTenantId: string) => {
	try {
		return await fetcher.get<{ url: string }>(
			`${MIGRATION_BASE_PATH}/migration/report/${legacyTenantId}`,
		);
	} catch (error) {
		return { url: null };
	}
};

export const enableSupportAccount = (organizationId: string) =>
	useMutation<AxiosResponse, AxiosError>({
		mutationFn: () =>
			fetcher.post(
				`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${organizationId}/support/user`,
				null,
			),
	});

export const disableSupportAccount = (organizationId: string) =>
	useMutation<AxiosResponse, AxiosError>({
		mutationFn: () =>
			fetcher.delete(
				`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${organizationId}/support/user`,
			),
	});

export const getSupportAccount = async (organizationId: string) => {
	try {
		return await fetcher.get<User>(
			`${ORGANIZATIONS_YGGDRASIL_BASE_PATH}/${organizationId}/support/user`,
		);
	} catch (error) {
		return null;
	}
};

export const useGetReports = (filters: string[]) =>
	useMutation<AxiosResponse, AxiosError>({
		mutationFn: () =>
			fetcher.get(
				`${FUNCTION_URL_REPORTS_BASE_PATH}/reports?filters=${filters.join(",")}`,
			),
	});

export const getMigrationStatus = (organizationId: string) => {
  const queryKey = [organizationQueryKey, 'migration-status'];

  return useQuery(
    queryKey,
    () => fetcher.get<{ isMigratingEvents: boolean; isMigratingTelemetry: boolean; isMigratingLongTerm: boolean }>(`${MIGRATION_BASE_PATH}/migration/status/${organizationId}`),
    {
      enabled: !!organizationId,
      staleTime: 1_000,
      cacheTime: 0
    },
  );
};

export const useMigrateLongTermBackup = () => {
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse, AxiosError, { legacyTenantId: string, tenantId: string }>(({ legacyTenantId, tenantId }) =>
    fetcher.post(`${MIGRATION_BASE_PATH}/migration/migrateLongTerm`, {
      legacyTenantId,
      tenantId
    }),
    {
      onSuccess: () => queryClient.invalidateQueries([organizationQueryKey]),
    }
  );
};
