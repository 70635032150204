import { Organization } from '@eppendorf/vnls-user-tenant-utils';

import { OrganizationFormProfile } from '$features/organizations/form/organization-form-profile';

const mapOrganizationToFormValues = (
  organization: Organization,
): OrganizationFormProfile => ({
  name: organization.name ?? '',
  contactFirstName: organization.contactFirstName ?? '',
  contactLastName: organization.contactLastName ?? '',
  contactEmail: organization.contactEmail ?? '',
  adminFirstName: organization.adminFirstName ?? '',
  adminLastName: organization.adminLastName ?? '',
  adminEmail: organization.adminEmail ?? '',
  subscribedDevices: organization.subscribedDevices ?? 0,
  subscription: organization.subscription ?? '',
  type: organization.type ?? '',
});

const mapFormValuesToUpdateRequest = (
  id: string,
  organizationFormValues: OrganizationFormProfile,
): Partial<Organization> => ({
  id,
  ...organizationFormValues,
  subscribedDevices: Number.parseInt(
    organizationFormValues.subscribedDevices.toString(),
    10,
  ),
});

const mapFormValuesToCreateRequest = (
  organizationFormValues: OrganizationFormProfile,
): Partial<Organization> => ({
  ...organizationFormValues,
  subscribedDevices: Number.parseInt(
    organizationFormValues.subscribedDevices?.toString() ?? '0',
    10,
  ),
});

export const OrganizationFormMappers = {
  mapOrganizationToFormValues,
  mapFormValuesToUpdateRequest,
  mapFormValuesToCreateRequest,
};
