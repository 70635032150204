import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import {
  type Organization,
  InvitationStatus as AdminStatus,
} from '@eppendorf/vnls-user-tenant-utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  downloadMigrationReport,
  useGetMigrationReport,
  useInviteUsers,
  useMigrateEvents,
  useMigrateOrganizations,
} from '$features/organizations';
import { MigrateLongTermActionButton } from '$features/organizations/detail/actions/MigrateLongTermActionButton';
import { MigrateTelemetryActionButton } from '$features/organizations/detail/actions/MigrateTelemetryActionButton';

export default function OrganizationActions({
  organization,
  onEditPress,
}: {
  organization: Organization;
  onEditPress: () => void;
}) {
  const { t } = useTranslation();
  const migrateOrganizationMutation = useMigrateOrganizations();
  const {
    mutateAsync: migrateEvents,
    isSuccess: isEventsMigrated,
    isError: isEventsMigratedError,
    isLoading: isEventsMigratedLoading,
    error: migrateEventsError,
  } = useMigrateEvents();
  const [inviteUsersConfirmationDialogOpen, setInviteUsersConfirmationDialogOpen] =
    useState(false);
  const [migrateEventsConfirmationDialogOpen, setMigrateEventsConfirmationDialogOpen] =
    useState(false);
  const {
    mutateAsync: inviteUsers,
    isLoading: isInviteUsersLoading,
    isSuccess: isInvitationSent,
    isError: isInviteUsersError,
  } = useInviteUsers();
  const { data: migrationReport, isLoading: isMigrationReportLoading } =
    useGetMigrationReport(organization?.legacyTenantId || '');

  const isMigratedOrganization = !!organization?.legacyTenantId;
  const showInviteUsersButton =
    isMigratedOrganization &&
    (organization.adminStatus === AdminStatus.Active ||
      organization.adminStatus === AdminStatus.Invited);
  const showMigrateOrganizationButton =
    isMigratedOrganization && organization.adminStatus === AdminStatus.Imported;
  const showDownloadMigrationReportButton =
    isMigratedOrganization && organization.adminStatus !== AdminStatus.Imported;
  const disabledInviteUsersButton =
    organization.adminStatus !== AdminStatus.Active || isInviteUsersLoading;
  const isMigratedAdminActive =
    isMigratedOrganization && organization.adminStatus === AdminStatus.Active;
  const disableMigrationEventsButton =
    isEventsMigratedLoading ||
    isMigrationReportLoading ||
    migrationReport?.includes('[EVENT_MIGRATION_SUCCESS_MARKER]');

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadReport = async () => {
    setIsDownloading(true);

    const { url } = await downloadMigrationReport(organization?.legacyTenantId || '');
    if (url === null) {
      toast('Unable to download report. Try again later.', { type: 'error' });
      setIsDownloading(false);
      return;
    }
    window.open(url, '_blank');

    setIsDownloading(false);
  };

  const handleInviteUsers = async (tenantId: string) => {
    setInviteUsersConfirmationDialogOpen(false);
    await inviteUsers(tenantId);
  };

  const handleMigrateEvents = async () => {
    setMigrateEventsConfirmationDialogOpen(false);
    await migrateEvents(organization?.legacyTenantId || '');
  };

  useEffect(() => {
    if (
      migrateOrganizationMutation.isError ||
      isInviteUsersError ||
      isEventsMigratedError
    ) {
      const eventsErrorMessage =
        typeof migrateEventsError?.response?.data === 'string'
          ? migrateEventsError.response.data
          : migrateEventsError?.message;

      const message =
        migrateOrganizationMutation.error?.message ||
        eventsErrorMessage ||
        'Unable to perform action. Try again later.';

      toast(message, { type: 'error' });
    }
  }, [
    migrateOrganizationMutation.isError,
    isInviteUsersError,
    migrateOrganizationMutation.error?.message,
    migrateEventsError?.message,
    isEventsMigratedError,
    migrateEventsError?.response?.data,
  ]);

  useEffect(() => {
    if (isInvitationSent || migrateOrganizationMutation.isSuccess || isEventsMigrated) {
      toast('Success !', { type: 'success' });
    }
  }, [isInvitationSent, migrateOrganizationMutation.isSuccess, isEventsMigrated]);

  return (
    <>
      <div
        className={`flex flex-wrap flex__jc--${isMigratedOrganization ? 'space-between' : 'flex-end'} m-top-m`}
      >
        {showInviteUsersButton && (
          <div data-e2e-id="resend-user-invite-button">
            <Button
              disabled={disabledInviteUsersButton}
              onClick={() => setInviteUsersConfirmationDialogOpen(true)}
            >
              <Icon
                name="mail"
                size={IconSizeClasses.Small}
                className={disabledInviteUsersButton ? 'bg-gray-400' : 'bg-white'}
              />
              {isInviteUsersLoading
                ? t('organizationDetails.inviting')
                : t('organizationDetails.inviteUsers')}
            </Button>
          </div>
        )}

        {showMigrateOrganizationButton && (
          <div data-e2e-id="resend-user-invite-button">
            <Button
              onClick={() =>
                migrateOrganizationMutation.mutateAsync(
                  organization?.legacyTenantId || '',
                )
              }
              disabled={migrateOrganizationMutation.isLoading}
            >
              {migrateOrganizationMutation.isLoading
                ? t('organizationDetails.migrating')
                : t('organizationDetails.startMigration')}
            </Button>
          </div>
        )}

        {showDownloadMigrationReportButton && (
          <div data-e2e-id="download-migration-report-button">
            <Button disabled={isDownloading} onClick={handleDownloadReport}>
              <Icon
                name="export"
                size={IconSizeClasses.XSmall}
                className="bg-white m-right-s"
              />
              {isDownloading
                ? t('shared.downloading')
                : t('organizationDetails.downloadReport')}
            </Button>
          </div>
        )}

        <div data-e2e-id="edit-organization-button">
          <Button onClick={onEditPress}>
            <Icon
              name="edit"
              size={IconSizeClasses.XSmall}
              className="bg-white m-right-xs"
            />
            {t('shared.edit')}
          </Button>
        </div>
      </div>

      <div
        data-e2e-id="migrate-events-button"
        className="flex flex__dir--row m-top-m flex-wrap flex__jc--space-around"
        style={{ gap: 10 }}
      >
        {isMigratedAdminActive && (
          <>
            <MigrateTelemetryActionButton organization={organization} migrationReport={migrationReport} />

            <MigrateLongTermActionButton organization={organization} migrationReport={migrationReport} />

            <Button
              onClick={() => setMigrateEventsConfirmationDialogOpen(true)}
              disabled={
                migrateOrganizationMutation.isLoading || disableMigrationEventsButton
              }
            >
              <Icon
                name="import"
                size={IconSizeClasses.XSmall}
                className={`m-right-xs ${disableMigrationEventsButton ? 'bg-gray-400' : 'bg-white'}`}
              />
              {migrateOrganizationMutation.isLoading
                ? t('organizationDetails.migrating')
                : t('organizationDetails.migrateEvents')}
            </Button>
          </>
        )}
      </div>

      <Dialog isOpen={inviteUsersConfirmationDialogOpen} hideCloseButton>
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="warning"
            className="bg-orange-500 m-bottom-xxl"
            size={IconSizeClasses.XLarge}
          />

          <div className="title">{t('inviteUsers.title')}</div>

          <div className="body-regular">{t('inviteUsers.message')}</div>
        </div>

        <Dialog.Actions>
          <Button
            onClick={() => setInviteUsersConfirmationDialogOpen(false)}
            variant="secondary"
          >
            {t('inviteUsers.cancel')}
          </Button>
          <Button
            onClick={() => handleInviteUsers(organization.legacyTenantId || '')}
            variant="primary"
          >
            {t('inviteUsers.confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>

      <Dialog isOpen={migrateEventsConfirmationDialogOpen} hideCloseButton>
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="warning"
            className="bg-orange-500 m-bottom-xxl"
            size={IconSizeClasses.XLarge}
          />

          <div className="title">{t('attentionConfirmation.title')}</div>

          <div className="body-regular">{t('attentionConfirmation.message')}</div>
        </div>

        <Dialog.Actions>
          <Button
            onClick={() => setMigrateEventsConfirmationDialogOpen(false)}
            variant="secondary"
          >
            {t('attentionConfirmation.cancel')}
          </Button>
          <Button onClick={() => handleMigrateEvents()} variant="primary">
            {t('attentionConfirmation.confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
