import { Button, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import {
  Organization,
  OrganizationWithDevices,
  type Pagination as IPagination,
} from '@eppendorf/vnls-user-tenant-utils';
import { createColumnHelper } from '@tanstack/react-table';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueryString } from '$shared/custom-hooks/use-query-string';
import { getLocalizedDateFromUtcString } from '$shared/utils/date.utils';

import { Loader } from '$components/loader/loader';
import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { Overview } from '$components/overview-box/overview-box';
import { Pagination } from '$components/table/pagination/pagination';
import { SortableTable } from '$components/table/sortable-table/sortable-table';
import { AddOrganizationDialogWrapper } from '$features/add-organization/add-organization-dialog/add-organization-dialog-wrapper';
import { InvitationStatus } from '$features/organizations/detail/invitation-status';
import { useOrganizationsWithQueryParams } from '$features/organizations/organizations.api';
import { useIsSearchParamEqualTo } from '$hooks/index';

import { SIDECAR_ROUTE_PARAMS } from 'src/sidecar-route-params';
import { SIDECAR_ROUTES } from 'src/sidecar-routes.enum';

const initialPageIndex = 1;
const initialPageSize = 10;

export function OrganizationOverview(): ReactElement {
  const { t } = useTranslation();
  const isSearchParamEqualTo = useIsSearchParamEqualTo();

  const { getQueryParams, setQueryParams } = useQueryString<
    IPagination & { sidecarRoute: string }
  >();

  const [{ page, pageSize }, setPagination] = useState<IPagination>({
    page: getQueryParams()?.page ?? initialPageIndex,
    pageSize: getQueryParams()?.pageSize ?? initialPageSize,
  });

  const [isOrganizationDialogOpen, setIsOrganizationDialogOpen] = useState(false);

  const [sortBy, setSortBy] = useState<string>('-createdAt');

  const handlePageSizeChange = (newPageSize: string) => {
    setQueryParams({ page: 1, pageSize: Number(newPageSize) });
    setPagination({ page: 1, pageSize: Number(newPageSize) });
  };

  const handlePageChange = (newPage: number) => {
    setQueryParams({ page: newPage });
    setPagination({ pageSize, page: newPage });
  };

  function handleSortChange(sortKey: string) {
    if (sortBy === `+${sortKey}`) {
      setSortBy(`-${sortKey}`);
    } else {
      setSortBy(`+${sortKey}`);
    }
  }

  const handleOnRowClick = ({ id }: Organization) => {
    setQueryParams({
      sidecarRoute: SIDECAR_ROUTES.Organization,
      [SIDECAR_ROUTE_PARAMS.organization.id]: id,
    });
  };

  const { data, isLoading, isError } = useOrganizationsWithQueryParams({
    page,
    pageSize,
    sort: sortBy,
  });

  const columnHelper = createColumnHelper<OrganizationWithDevices>();
  const columns = useMemo(
    () => [
      columnHelper.accessor('adminStatus', {
        id: 'adminStatus',
        header: () => t('shared.status'),
        // eslint-disable-next-line react/no-unstable-nested-components -- accepted
        cell: (info) => <InvitationStatus status={info.getValue()} />,
      }),
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('organizationOverview.name'),
        cell: (info) => info.renderValue(),
        meta: {
          isSortable: true,
        },
      }),
      columnHelper.accessor(
        (row: Organization) =>
          `${row.contactFirstName} ${row.contactLastName}\n${row.contactEmail}`,
        {
          id: 'contact',
          header: () => t('organizationOverview.contact'),
          // eslint-disable-next-line react/no-unstable-nested-components -- accepted
          cell: (info) => (
            <>
              <p>{info.getValue().split('\n')[0]}</p>
              <p>{info.getValue().split('\n')[1]}</p>
            </>
          ),
        },
      ),
      columnHelper.accessor(
        (row: Organization) =>
          `${row.adminFirstName} ${row.adminLastName}\n${row.adminEmail}`,
        {
          id: 'admin',
          header: () => t('organizationOverview.admin'),
          // eslint-disable-next-line react/no-unstable-nested-components -- accepted
          cell: (info) => (
            <>
              <p>{info.getValue().split('\n')[0]}</p>
              <p>{info.getValue().split('\n')[1]}</p>
            </>
          ),
        },
      ),
      columnHelper.accessor('pairedDevices', {
        id: 'pairedDevices',
        header: () => t('organizationOverview.pairedDevices'),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('pendingDevices', {
        id: 'pendingDevices',
        header: () => t('organizationOverview.pendingDevices'),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('createdAt', {
        id: 'createdAt',
        header: () => t('organizationOverview.createdAt'),
        cell: (info) => getLocalizedDateFromUtcString(info.getValue()),
      }),
    ],
    [],
  );

  return (
    <Overview>
      <Overview.Header>
        <div className="flex">
          <Icon name="organization" size={IconSizeClasses.Small} className="m-right-s" />
          <h1 className="title">{t('addOrganization.overview')}</h1>
        </div>
        <AddOrganizationDialogWrapper
          isOpen={isOrganizationDialogOpen}
          onOpenChange={(open) => setIsOrganizationDialogOpen(open)}
        />

        <Button
          variant="primary"
          className="p-left-l"
          onClick={() => setIsOrganizationDialogOpen(true)}
        >
          <Icon
            name="plus"
            size={IconSizeClasses.XSmall}
            className="bg-white m-right-s"
          />

          {t('organizationOverview.addOrganization')}
        </Button>
      </Overview.Header>
      <Overview.Body>
        {isLoading && <Loader />}

        {isError && <LoadingErrorHint />}

        {!isLoading && !isError && data && (
          <div style={{ overflowX: 'auto' }}>
            <SortableTable<OrganizationWithDevices[], OrganizationWithDevices>
              data={data?.data}
              columns={columns}
              onSortChange={(sortKey) => handleSortChange(sortKey)}
              currentSortKey={sortBy}
              onRowClick={handleOnRowClick}
              isSelectedRow={({ id }) =>
                isSearchParamEqualTo<OrganizationWithDevices>('id', id)
              }
            />
            {data?.pagination && (
              <Pagination
                paginationData={data?.pagination}
                onPageSizeChange={handlePageSizeChange}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}

        {!isLoading && !isError && data && data.data.length === 0 && (
          <div className="flex flex__dir--column flex__ai--center bg-gray-50 p-xxxl">
            <p className="m-bottom-xxl">{t('organizationOverview.emptyState')}</p>
          </div>
        )}
      </Overview.Body>
    </Overview>
  );
}
