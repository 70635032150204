import { Auth } from '@aws-amplify/auth';
import { Button, Icon, IconSizeClasses, Tooltip } from '@eppendorf/vnls-react-components';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Reporting } from '$features/reporting/reporting';

export function Header(): ReactElement {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <header className="flex flex__jc--space-between p-y-m p-x-xxxl border-bottom-s border-solid border-color-gray-300 bg-white">
      <Icon name="vn-logo-long" className="sidebar__logo--long" />
      <div className="flex__dir--row">
        <Button
          id="reports"
          className="m-right-xxl"
          onClick={() => setOpen(true)}
          leadingIcon={
            <Tooltip
              trigger={
                <div>
                  <Icon name="charts" size={IconSizeClasses.XSmall} alt="Reports" />
                </div>
              }
            >
              {t('shared.reports')}
            </Tooltip>
          }
          variant="tertiary"
        />
        <Button
          id="logout"
          onClick={() => Auth.signOut()}
          leadingIcon={
            <Tooltip
              trigger={
                <div>
                  <Icon
                    name="logout"
                    size={IconSizeClasses.XSmall}
                    alt={t('shared.logout')}
                  />
                </div>
              }
            >
              {t('shared.logout')}
            </Tooltip>
          }
          variant="tertiary"
        />
      </div>
      <Reporting show={open} setShow={(status) => setOpen(status)} />
    </header>
  );
}
