import { Environment } from './env';

export const apiUrl = import.meta.env.PUBLIC_API_URL;

export const graphqlUrl = import.meta.env.PUBLIC_GRAPHLQL_ENDPOINT;

export const visionizeApiUrl = import.meta.env.PUBLIC_VISIONIZE_API_URL;

export const reportingFunctionUrl = import.meta.env.PUBLIC_REPORTING_FUNCTION_URL;

export const environment: Environment = {
  apiUrl,
  graphqlUrl,
  visionizeApiUrl,
  reportingFunctionUrl,
};
