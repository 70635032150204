export const en = {
	translation: {
		shared: {
			cancel: "Cancel",
			edit: "Edit",
			save: "Save",
			saveChanges: "Save changes",
			unsavedChangesWarning: "You have unsaved changes",
			unsavedChangesQuestion: "Do you want to discard your changes?",
			close: "Close",
			discard: "Discard",
			noDataToShow: "No data to show",
			continue: "Continue",
			logout: "Logout",
			status: "Status",
			actions: "Actions",
			delete: "Delete",
			intlDateTime: "{{val, dateTime}}",
			email: "Email",
			back: "Back",
			tryAgain: "Try again",
			id: "ID",
			created: "Created",
			by: "by",
			on: "on",
			downloading: "Downloading...",
			reports: "Reports",
			download: "Download",
		},
		validationMessages: {
			required: "This field can not be empty.",
			maxLength120: "Field must not exceed 120 characters.",
		},
		errors: {
			oops: "Oops!",
			unexpected: "Sorry, an unexpected error has occurred.",
			unauthorized: "You are unauthorized to execute this actions.",
			informationCouldNotBeLoaded: "Information could not be loaded",
			contactSupport: "If the problem persists",
			contactSupport2: "contact the support",
		},
		addOrganization: {
			overview: "Organization overview",
			addNewOrganization: "Add new organization",
			adminUserAlreadyExists: "Admin user already exists",
			name: "Organization name",
			contactFirstName: "Contact first name",
			contactLastName: "Contact last name",
			contactEmail: "Contact email",
			adminFirstName: "Admin first name",
			adminLastName: "Admin last name",
			adminEmail: "Admin email",
			subscription: "Subscription",
			subscribedDevices: "Number of licensed devices",
			descriptionAddOrganization: "Add a new organization to VisioNize",
			create: "Create",
			errorMessageAdminEmailWrongFormat:
				"Admin email is not in the correct format",
			errorMessageAdminEmailMaxLength: "Admin email is too long",
			errorMessageContactEmailWrongFormat:
				"Contact email is not in the correct format",
			errorMessageContactEmailMaxLength: "Contact email is too long",
			errorMessageNumberWrongFormat: "Only numbers are allowed.",
			addOrganizationSuccess: "Organization added",
			subscriptions: {
				Essentials: "Essentials",
				SampleSafetyPlus: "Sample Safety Plus",
			},
			types: { Academia: "Academia", Industry: "Industry" },
			type: "Organization Type",
			addOrganizationSuccessDescription:
				"Organization <bold>{{name}}</bold> was successfully created. You can edit it in the organization details later or add a new one.",
		},
		organizationOverview: {
			addOrganization: "Add organization",
			admin: "Admin",
			createdAt: "Created",
			contact: "Contact",
			name: "Organization name",
			emptyState: "Start adding first organization to VisioNize",
			pairedDevices: "Paired Devices",
			pendingDevices: "Pending Devices",
		},
		organizationDetails: {
			name: "Organization name",
			type: "Organization Type",
			subscription: "Subscription",
			subscribedDevices: "Subscribed Devices",
			contactName: "Contact name",
			contactEmail: "Contact email",
			adminName: "Admin name",
			adminEmail: "Admin email",
			inviteSent: "Invite sent",
			resendInvite: "Resend invite to Admin",
			inviteSentSuccess: "Invite successfully resent",
			inviteUsers: "Invite Users",
			startMigration: "Start migration",
			migrateEvents: "Migrate Events",
      migrateTelemetry: "Migrate Telemetry",
      telemetryMigrated: "Telemetry Migrated",
      migrateLongTerm: "Migrate 90d Backup",
      longTermMigrated: "90d Backup Migrated",
			migrating: "Migrating...",
			inviting: "Inviting...",
			downloadReport: "Migration Report",
			supportAccount: "Support Account",
			enableSupportAccount: "Enable",
			disableSupportAccount: "Disable",
			disclaimerSupportAccount:
				"By enabling support account for this organization, other previous enabled account will be removed.",
		},
		userDetail: {
			title: "User details",
			myProfile: "My profile",
		},
		pagination: {
			pageSize: "Rows per page",
			itemRange: "{{startItem}} - {{endItem}} of {{totalItems}} items",
		},
		adminStatus: {
			active: "Active",
			invited: "Invite sent",
			migrated: "Migrated",
			imported: "Imported",
			unknown: "Unknown",
		},
		unsaved: {
			title: "You have unsaved changes",
			message: "Do you want to discard your changes?",
			save: "Save changes",
			discard: "Discard",
		},
		inviteUsers: {
			title: "Attention",
			message:
				"By confirming all users will immediately receive an invitation email. Do you want to proceed?",
			confirm: "Confirm",
			cancel: "Cancel",
		},
		attentionConfirmation: {
			title: "Attention",
			message:
				"Make sure that you have triggered a fresh export from SAG. You can only do this migration once! Do you want to proceed?",
      messageAlert: "Attention! This migration will take a while to complete and can only be done once. Do you want to proceed?",
			confirm: "Confirm",
			cancel: "Cancel",
		},
	},
};
