import {
  Button,
  Dialog,
  MultiSelect,
  MultiSelectOption,
} from '@eppendorf/vnls-react-components';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Loader } from '$components/loader/loader';
import { useGetReports } from '$features/organizations';

interface ReportsProps {
  show: boolean;
  setShow: (status: boolean) => void;
}

export function Reporting({ show, setShow }: ReportsProps) {
  const ref = useRef();
  const { t } = useTranslation();
  const [selectedFilters, setSelectedFilters] = useState<MultiSelectOption[]>([]);
  const options = useMemo(
    () => [
      {
        value: 'organizations',
        label: 'Organizations',
      },
      {
        value: 'devices',
        label: 'Devices',
      },
      {
        value: 'users',
        label: 'Users',
      },
      {
        value: 'notifications',
        label: 'Notifications',
      },
    ],
    [],
  );
  const { mutateAsync: getReports, isLoading } = useGetReports(
    selectedFilters.map(({ value }) => value),
  );

  const handleOnReportsDownload = async () => {
    try {
      const { data } = await getReports();
      const link = document.createElement('a');
      link.href = data.url;
      link.setAttribute('download', 'reports.zip');
      document.body.appendChild(link);
      link.click();

      toast('Reports downloaded successfully', { type: 'success' });
    } catch (error) {
      // eslint-disable-next-line no-console -- for debugging
      console.error(error);
      toast('Unable to download reports. Try again later.', { type: 'error' });
    }
  };

  return (
    <Dialog isOpen={show} onOpenChange={(open) => setShow(open)}>
      <Dialog.Title>{t('shared.reports')}</Dialog.Title>
      <div style={{ minHeight: 300 }}>
        <MultiSelect
          className="reports-filters"
          value={selectedFilters}
          options={options}
          ariaLabelledBy="reports-filters"
          placeholder="Select filters..."
          onChange={(x) => setSelectedFilters(x)}
          ref={ref}
        />
      </div>
      <Dialog.Actions className="m-top-xxl">
        {isLoading && <Loader />}
        <Button
          className="btn--solid"
          disabled={!selectedFilters.length || isLoading}
          onClick={handleOnReportsDownload}
        >
          {isLoading ? t('shared.downloading') : t('shared.download')}
        </Button>
        <Dialog.Close>
          <Button className="btn--solid">{t('shared.close')}</Button>
        </Dialog.Close>
      </Dialog.Actions>
    </Dialog>
  );
}
