import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { Organization } from '@eppendorf/vnls-user-tenant-utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  getMigrationStatus,
  useMigrateLongTermBackup,
} from '$features/organizations';

export function MigrateLongTermActionButton({ organization, migrationReport }: { organization: Organization, migrationReport?: string | null }) {
  const { t } = useTranslation();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { data: status, isLoading: isStatusLoading, refetch: refetchStatus } = getMigrationStatus(organization.id);
  const { mutateAsync: migrateBackup, isLoading, error, isError, isSuccess } = useMigrateLongTermBackup();

  const isMigrating = isLoading || status?.isMigratingLongTerm;
  const isMigrationDone = !!migrationReport?.includes('[LONG_TERM_MIGRATION_SUCCESS_MARKER]');

  const getButtonText = () => {
    if (isMigrating) {
      return t('organizationDetails.migrating');
    }
    if (isMigrationDone) {
      return t('organizationDetails.longTermMigrated');
    }
    return t('organizationDetails.migrateLongTerm');
  };

  useEffect(() => {
    if (!isSuccess && !isError) {
      return;
    }

    if (isSuccess) {
      refetchStatus();
    }

    // @ts-expect-error - error is defined in the response
    const message = isError ? (error.response?.data?.message || error?.message) : 'Migration started. This may take a while.';
    toast(message, { type: isError ? 'error' : 'success' });
  }, [isSuccess, isError, error]);

  if (organization.subscription !== 'SampleSafetyPlus') {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setOpenConfirmationDialog(true)}
        disabled={isMigrating || isMigrationDone || isStatusLoading}
      >
        <Icon
          name={isMigrationDone ? 'success' : 'archive'}
          size={IconSizeClasses.XSmall}
          className={`m-right-xs ${(isMigrating || isMigrationDone) ? 'bg-gray-400' : 'bg-white'}`}
        />
        {getButtonText()}
      </Button>

      <Dialog isOpen={openConfirmationDialog} hideCloseButton>
        <div className="flex flex__dir--column flex__ai--center">
          <Icon
            name="warning"
            className="bg-orange-500 m-bottom-xxl"
            size={IconSizeClasses.XLarge}
          />

          <div className="title">{t('attentionConfirmation.title')}</div>

          <div className="body-regular">{t('attentionConfirmation.messageAlert')}</div>
        </div>

        <Dialog.Actions>
          <Button
            onClick={() => setOpenConfirmationDialog(false)}
            variant="secondary"
          >
            {t('attentionConfirmation.cancel')}
          </Button>
          <Button
            onClick={() => {
              setOpenConfirmationDialog(false);
              migrateBackup({
                legacyTenantId: organization.legacyTenantId || '',
                tenantId: organization.id || '',
              });
            }}
            variant="primary"
          >
            {t('attentionConfirmation.confirm')}
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
}
